<template>
  <div class="taskcenter_content">
    <div class="task_header">
      <p
              :class="{active:isSelect==i}"
              v-for="(item,i) in selectList"
              :key="i"
              @click="select(i)"
      >{{item}}</p>
    </div>
    <div class="task_content">
      <div class="release_task" v-show="isSelect == 0">
        <div class="isbtn">
          <el-radio v-model="radio" label="1">普通任务</el-radio>
          <el-radio v-model="radio" label="2">系统任务</el-radio>
        </div>

        <!-- 普通任务 -->
        <el-form :model="from" ref="form" label-width="110px" :rules="rules">
          <el-form-item label></el-form-item>
          <el-form-item v-show="radio == 2" label="系统任务" prop="systemtask">
            <el-cascader
                    v-model="cascaderValue"
                    :options="systemTaskList"
                    :props="{value:'id',label:'name',children:'subClassification'}"
                    @change="cascaderHandleChange"></el-cascader>
            <el-select v-model="cascaderValueTwo" placeholder="请选择" style="margin-left: 20px" @change="cascaderHandleTwoChange">
              <el-option
                      v-for="item in systemTaskListTwo"
                      :key="item.task_id"
                      :label="item.task_title"
                      :value="item.task_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="任务标题" prop="title">
            <el-input
                    type="text"
                    show-word-limit
                    v-model="from.title"
                    style="width:304px"
                    maxlength="30"
                    placeholder="请输入任务标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="任务描述" prop="describe">
            <div @click="test" id="article_content"></div>
          </el-form-item>
          <el-form-item style="position: relative;" label="上传封面" prop="cover">
            <span class="imggeshi">378*162px</span>
            <el-upload
                    class="upload-demo upload-covercss"
                    ref="upload"
                    action="string"
                    accept="image/jpeg, image/png, image/jpg"
                    list-type="picture-card"
                    :before-upload="onBeforeUploadImage"
                    :http-request="UploadImage"
                    :on-change="fileChange"
                    :file-list="fileList"
            >
              <el-button class="uploadCove" style="position: relative;" size="small" type="text">
                {{uploadImgContent}}
                <span class="addimg">+</span>
              </el-button>
              <img v-if="anewUploadImg != ''" style="position: absolute;left: 170px;width: 148px;height: 148px" :src="anewUploadImg" alt="">
            </el-upload>
          </el-form-item>

          <el-form-item label="类型分类" prop="taskclass">
            <el-select v-model="from.taskclass" @change="selectTaskClass">
              <el-option
                      style="z-index: 99"
                      v-for="item in classIfl"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="截至日期" prop="time">
            <el-date-picker v-model="from.time" type="datetime" placeholder="选择日期时间"></el-date-picker>
          </el-form-item>

          <el-form-item label="发布平台" prop="fabu">
            <el-select v-model="from.fabu">
              <el-option
                      v-for="item in platformList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="任务对象" prop="classGrable">
            <el-button type="primary" @click="dialogFormVisible = true">选择班级</el-button>
            <div class="selectClassList">
              <span v-for="(item, index) in checkedCities">{{item.name}}
            <i @click="deleCheckedCities(index)">x</i>
            </span>
            </div>
          </el-form-item>

          <el-dialog title="选择班级" :visible.sync="dialogFormVisible" style="z-index: 99999!important;">
            <el-checkbox-group
                    size="small"
                    v-model="checkedCities"
                    :min="1"
            >
              <el-checkbox v-for="(city,index) in cities" :label="city" :key="index">{{city.name}}</el-checkbox>
            </el-checkbox-group>
            <div slot="footer" class="dialog-footer">
              <el-button @click="dialogFormVisibleFalse">取 消</el-button>
              <el-button type="primary" @click="dialogFormVisi">确 定</el-button>
            </div>
          </el-dialog>

          <el-form-item label="上传附件" prop="fil">
            <el-upload
                    accept=".rar,.zip"
                    class="upload-demo"
                    action="string"
                    multiple
                    :limit="1"
                    :before-remove="beforeRemove"
                    :before-upload="beforeUploadFile"
                    :http-request="uploadFile"
                    :file-list="fileLss"
            >
              <el-button size="small" type="primary">上传附件</el-button>
            </el-upload>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="getAddtask(from)">发布任务</el-button>
            <el-button @click="closeUpload">取消</el-button>
          </el-form-item>
        </el-form>

      </div>
      <!-- 任务列表 -->
      <div class="task_list" v-show="isSelect == 1">
        <div class="tasjlist_header">
          <div>
            <p>任务类型：</p>
            <span style="cursor: pointer" @click="allClas" :class="{activeClass:isFlei == 0}">全部</span>
            <span
                    style="cursor: pointer"
                    @click="isFleig(item.id)"
                    :class="{activeClass:isFlei == item.id}"
                    v-for="item in classIfl"
                    :key="item.id"
            >{{item.name}}</span>
          </div>
          <div>
            <p>任务筛选：</p>
            <span
                    style="cursor: pointer"
                    @click="isFleigs(index)"
                    v-for="(item,index) in renwusx"
                    :class="{activeClass:isFleis == index}"
                    :key="index"
            >{{item}}</span>
          </div>
        </div>
        <div style="position: relative" v-show="isClass == 1">
          <el-table
                  :data="taskallTypeList"
                  class="college-table"
                  style="width: 100%; flex: 1;height: calc(100vh - 316px);overflow-y: auto"
                  size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}"
                  :cell-style="{fontSize: '12px',color: '#333'}"
          >
            <el-table-column
                    label="序号"
                    width="140"
                    align="center"
                    class-name="student-name"
                    type="index"
            >
<!--              <template slot-scope="scope">-->
<!--                <span class="student-name-title">{{scope.row.task_id}}</span>-->
<!--              </template>-->
            </el-table-column>

            <el-table-column prop="task_cover" label align="right" class-name="student-name">
              <template slot-scope="scope">
                <img style="width:80px;height:37px;" :src="scope.row.task_cover" />
              </template>
            </el-table-column>

            <el-table-column prop="task_title" label="任务详情" align="left" class-name="student-name">
              <template slot-scope="scope">
                <p class="studentNameTitle">{{scope.row.task_title}}</p>
                <p class="studentNameContent" v-html="scope.row.task_content"></p>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="参与人数" align="center" class-name="student-name">
              <template slot-scope="scope">
                <span class="student-name-title">{{scope.row.num}}</span>
              </template>
            </el-table-column>
            <el-table-column
                    prop="task_state"
                    label="任务状态"
                    align="center"
                    class-name="student-name"
            >
              <template slot-scope="scope">
                <span class="student-name-title">{{scope.row.task_state}}</span>
              </template>
            </el-table-column>
            <el-table-column
                    prop="create_time"
                    label="发布时间"
                    align="center"
                    class-name="student-name"
            >
              <template slot-scope="scope">
                <span class="student-name-title">{{scope.row.create_time}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="toDetailView(scope.row)">查看详情</el-button>
                <el-button type="text" size="small" @click="editTeacher(scope.row)">编辑</el-button>
                <el-button type="text" size="small" @click="detaTeacher(scope.row)">删除</el-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <div style="position: relative" v-show="isClass == 2">
          <el-table
                  :data="taskallScreenList"
                  class="college-table"
                  style="width: 100%; flex: 1;overflow: hidden"
                  size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}"
                  :cell-style="{fontSize: '12px',color: '#333'}"
          >
            <el-table-column
                    prop="task_id"
                    label="序号"
                    width="140"
                    align="center"
                    class-name="student-name"
            >
              <template slot-scope="scope">
                <span class="student-name-title">{{scope.row.task_id}}</span>
              </template>
            </el-table-column>

            <el-table-column prop="task_cover" label align="right" class-name="student-name">
              <template slot-scope="scope">
                <img style="width:80px;height:37px;" :src="scope.row.task_cover" />
              </template>
            </el-table-column>

            <el-table-column prop="task_title" label="任务详情" align="left" class-name="student-name">
              <template slot-scope="scope">
                <span class="student-name-title">{{scope.row.task_title}}</span>
                <br />
                <span class="student-name-title">{{scope.row.task_content}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="num" label="参与人数" align="center" class-name="student-name">
              <template slot-scope="scope">
                <span class="student-name-title">{{scope.row.num}}</span>
              </template>
            </el-table-column>
            <el-table-column
                    prop="task_state"
                    label="任务状态"
                    align="center"
                    class-name="student-name"
            >
              <template slot-scope="scope">
                <span class="student-name-title">{{scope.row.task_state}}</span>
              </template>
            </el-table-column>
            <el-table-column
                    prop="create_time"
                    label="发布时间"
                    align="center"
                    class-name="student-name"
            >
              <template slot-scope="scope">
                <span class="student-name-title">{{scope.row.create_time}}</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small">查看详情</el-button>
                <el-button type="text" size="small" @click="editTeacher(scope.row)">编辑</el-button>
                <el-button type="text" size="small" @click="detaTeacher(scope.row)">删除</el-button>
              </template>
            </el-table-column>

          </el-table>
        </div>
        <el-pagination
                class="pages-center"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import {getFormatDate} from './../../../utils/formatDate.js'
  import WE from "../../../utils/wEditor";
  export default {
    name: "taskCenter",
    data() {
      return {
        uploadImgContent:'上传封面',
        anewUploadImg:'',
        cascaderValue:[], //系统任务选择器
        cascaderValueTwo:[],
        selectList: ["发布任务", "任务列表"],
        classIfl: [], //分类列表
        platformList: [], //平台列表
        dialogFormVisible: false, //弹出选择班级
        cities: [], //班级数量列表
        checkedCities: [],
        rules: {
          title: [
            { required: true, message: "请输入任务标题", trigger: "blur" },
            { min: 3, max: 30, message: "长度在 3 到 30 个字符", trigger: "blur" }
          ],
          describe: [
            { required: true, message: "请输入任务描述", trigger: "blur" },
            {
              min: 3,
              max: 150,
              message: "长度在 3 到 150 个字符",
              trigger: "blur"
            }
          ],
          cover: [{ required: true, message: "请上传封面", trigger: "blur" }],
          taskclass: [
            { required: true, message: "请选择类型分类", trigger: "change" }
          ],
          time: [
            {
              type: "date",
              required: true,
              message: "请选择截至时间",
              trigger: "change"
            }
          ],
          fabu: [{ required: true, message: "请选择发布平台", trigger: "change" }],
          classGrable:[{required: true, message: "请选择任务对象", trigger: "change"}]
        },
        fileLss:[],
        isFileFormat:true, //判断文件是否符合要求，防止执行删除之前函数
        fileUrl:[],
        fileName:'',
        from: {
          id:'',
          systemtask:'', //系统任务
          title: "", //标题
          describe: "", //描述
          cover: "", //封面
          taskclass: "", //类型分类
          time: "", //截至时间
          fabu: "", //发布平台
          classGrable: "", //班级
          file:[] //附件
        },
        fileList: [],
        isClass:1,
        taskalllist: [], //全部任务列表
        taskallTypeList:[], //点击类型分类
        taskallScreenList:[], //点击筛选
        isSelect: 0,
        radio: "1",
        isFlei: 0,
        isFleis: 0,
        //分页
        adminPages: {
          currentPageNum: 1,
          eachPageNum: 10,
          total: 0
        },
        //   任务列表
        renwusx: ["全部", "进行中", "已截至"],
        systemTaskList:[],
        systemTaskListTwo:[],
        editorText:null,
        typeId:'',
        taskState:''
      };
    },
    created() {
      this.getClassify();
      this.getPlatform();
      this.getAllClass();
      this.getSystem();
      this.getClassCount();
      this.getSystemTask()
    },
    mounted() {
      this.editorText = new WE('#article_content', this.$api.artUploadImg,['head', 'bold', 'quote', 'underline', 'code', 'link', 'image', 'video']);
      // this.editorText.customConfig.zIndex=0;
      // editor.create()
    },
    methods: {
      selectTaskClass(val){
      },
      test(){
      },
      // 获取系统任务分类
      getSystemTask(){
        let parms = {};
        this.$httpteacher.axiosGetBy(this.$api.classification,parms,(res)=>{
          if(res.code==200){
            this.systemTaskList = res.data.data
          }
        })
      },
      // 跳转查看详情页面
      toDetailView(row){
        this.$router.push({
          path:'/teacher/taskcenter/viewdetails',
          query:{id:row.task_id}
        })
      },
      //获取班级数量
      getClassCount(){
        let parms = {};
        this.$httpteacher.axiosGetBy(this.$api.classList,parms,(res)=>{
          if(res.code == '200'){
            this.cities = res.list
          }
        })
      },
      select(i) {
        this.isSelect = i;
      },
      isFleig(id) {
        this.typeId = id;
        this.isFlei = id;
        this.getAllClass()
      },
      isFleigs(index) {
        this.isFleis = index;
        this.taskState = index;
        this.getAllClass()
      },
      //全部
      allClas() {
        this.typeId = ''
        this.isFlei = 0;
        this.getAllClass()
      },
      // 新增任务
      getAddtask(){
        this.from.describe = this.editorText.getWEHtml();
        if(this.from.title=='' || this.from.describe=='' || this.from.cover=='' || this.from.time=='' ||this.from.taskclass == '' || this.from.fabu == '' || this.from.classGrable == ''){
          this.$message.warning('必填项不能为空！')
        } else {
          let fileStr = JSON.stringify(this.from.file);
          let formatTime = getFormatDate(this.from.time)
          let fromData = new FormData();
          fromData.append('task_title',this.from.title);
          fromData.append('task_content',this.from.describe);
          fromData.append('task_cover',this.from.cover);
          fromData.append('task_end_time',formatTime);
          fromData.append('task_type_id',this.from.taskclass);
          fromData.append('task_platform_id',this.from.fabu);
          fromData.append('class_id',this.from.classGrable);
          fromData.append('file',fileStr);
          if(this.from.id != ''){
            fromData.append('task_id',this.from.id);
          }
          // return;
          this.$httpteacher.axiosPost(this.$api.save,fromData,(res)=>{
            if(res.code == 200){
              this.$message({
                type: "success",
                message: '发布成功',
                duration: 1000,
                onClose: () => {
                  this.getAllClass()
                }
              });
              this.$router.go(0)
            } else {
              this.$message({
                type: "error",
                message: res.message,
                duration: 1000
              });
            }
          },err=>{
            this.getAllClass()
            console.log(529,err)
            this.$message.warning('发布失败')
          })
        }

      },

      //系统任务
      getSystem(){
        // let params = {};
        // this.$httpteacher.axiosGetBy(this.$api.systemTaskDetail,params,(res)=>{
        //     console.log(467,res)
        // },err =>{
        //     console.log(470,err)
        // })
      },
      // 获取全部任务列表
      getAllClass() {
        let param = {
          page: this.adminPages.currentPageNum,
          limit: this.adminPages.eachPageNum //页面显示条数
        };
        if(this.typeId){
          param.type_id = this.typeId
        }
        if(this.taskState){
          param.task_state = this.taskState
        }
        this.$httpteacher.axiosGetBy(this.$api.taskList, param, res => {
          if (res.code == 200) {
            this.taskalllist = res.list;
            this.taskallTypeList = res.list;
            this.adminPages.total = res.total;
          }
        });
      },
      // 编辑
      editTeacher(row) {
        let param = {
          'task_id' : row.task_id
        }
        this.$httpteacher.axiosGetBy(this.$api.taskEdit,param,(res)=>{
          if(res.code == 200) {
            let timeDate = new Date();
            const resInfo = res.info;
            if(resInfo.task_cover){
              this.uploadImgContent = '重新上传';
              this.anewUploadImg = resInfo.task_cover;
            }
            this.from.id = row.task_id;
            this.from.title = resInfo.task_title;
            this.from.describe = resInfo.task_content;
            this.editorText.setWEHtml(resInfo.task_content);
            this.from.taskclass = resInfo.task_type_id;
            this.from.cover = resInfo.task_cover_file;
            let task_platform_id = resInfo.task_platform_id == 1 ? '抖音' : resInfo.task_platform_id == 2 ? '快手' : resInfo.task_platform_id == 3 ? '哔哩哔哩' : resInfo.task_platform_id == 4 ? '美拍' : resInfo.task_platform_id == 5 ? '西瓜视频' : resInfo.task_platform_id == 6 ? "火山" : '请选择'
            this.from.fabu = task_platform_id;
            this.from.time = timeDate;
            if (resInfo.task_file.length>0) {
              let obj = {};
              resInfo.task_file.map(item=>{
                obj = {
                  name: item.file_name,
                  url: item.file_url
                }
              })
            }
          }
        })
        // this.$router.go(0);
        this.isSelect = 0;

      },
      // 删除
      detaTeacher(row) {
        let that =this;
        const id = row.task_id;
        let param = {
          task_id: id
        };
        this.$confirm("删除系统任务后不能恢复，确定要删除吗？", "删除任务", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          that.$httpteacher.axiosGetBy(that.$api.delTask, param, res => {
            if (res.code == 200) {
              that.$message({
                type: "success",
                message: "删除成功"
              });
              that.getAllClass();
            } else {
              that.$message.warning(res.msg);
            }
          });
        });
      },
      // 获取分类列表
      getClassify() {
        let params = {};
        this.$httpteacher.axiosGetBy(this.$api.classification,params, (res)=>{
          if(res.code === 200){
            this.classIfl = res.data.data;
          }
        })
      },
      // 获取平台列表
      getPlatform() {
        let params = {};
        this.$httpteacher.axiosGetBy(this.$api.platformList, params, res => {
          this.platformList = res;
        });
      },
      //分页
      adminCurrentChange(val) {
        this.adminPages.currentPageNum = val;
        this.getAllClass();
      },
      //上传封面
      onBeforeUploadImage(file) {
        const isIMAGE = file.type === "image/jpeg" || "image/jpg" || "image/png";
        const isLt1M = file.size / 1024 / 1024 < 2;
        if (!isIMAGE) {
          this.$message.error("上传文件只能是图片格式!");
          this.fileList = [];
        }
        if (!isLt1M) {
          this.$message.error("上传文件大小不能超过 2MB!");
          this.fileList = [];
        }
        // 限制图片尺寸
        // let _this = this;
        // const isWidthHeight = new Promise(function(resolve, reject) {
        //   let reader = new FileReader();
        //   reader.onload = function(event) {
        //     let image = new Image();
        //     image.onload = function () {
        //       let width = this.width;
        //       let height = this.height;
        //       if (width!=378 || height != 162){
        //         _this.$alert('图片尺寸378*162！', '提示', {confirmButtonText: '确定'});
        //         _this.fileList = [];
        //         reject();
        //       }
        //       resolve();
        //     };
        //     image.src = event.target.result;
        //   }
        //   reader.readAsDataURL(file);
        // });
        // console.log(655,isWidthHeight)
        return isIMAGE && isLt1M;
      },
      UploadImage(param) {
        // console.log(358,param)
        let that = this;
        const formData = new FormData();
        formData.append("file", param.file);
        this.$httpteacher.axiosPost(
                this.$api.uploadImg,
                formData,
                res => {
                  if (res.code == 200) {
                    this.anewUploadImg = '';
                    that.from.cover = res.url;
                  } else if(res.code === 400){
                    this.fileList = []
                    this.$message('上传文件后缀不允许')
                  }
                },
                err => {
                  console.log(673,err)
                }
        );
      },
      uploadFile(param) {
        this.from.file = [];
        // this.fileLss = [];
        const formData = new FormData();
        formData.append("file", param.file);
        this.$httpteacher.axiosPost(
                this.$api.uploadFile,
                formData,
                res => {
                  if(res.code == 200){
                    // console.log()
                    this.fileUrl = res.url;
                    const arr = [];
                    const fileObj = {
                      "file_name":param.file.name,
                      "file_path":this.fileUrl
                    };
                    arr.push(fileObj)
                    this.from.file=arr;
                  }
                },
                err => {
                  console.log(err);
                }
        );
      },
      beforeUploadFile(file){
        // console.log(694,file)
        let testmsg=file.name.substring(file.name.lastIndexOf('.')+1)
        const extension = testmsg === 'rar'
        const extension2 = testmsg === 'zip'
        const isLt2M = file.size / 1024 / 1024 < 10
        if(!extension && !extension2) {
          this.fileLss = [];
          this.isFileFormat = false;
          this.$message({
            message: '上传文件只能是 rar、zip格式!',
            type: 'warning'
          });
        } else if(!isLt2M){
          this.fileLss = [];
          this.isFileFormat = false;
          this.$message({
            message: '上传文件大小不能超过10M!',
            type: 'warning'
          });
        }
        return extension || extension2 || isLt2M;
      },
      fileChange(file) {
        this.$refs.upload.clearFiles(); //清除文件对象
        //   this.cove = file.raw; // 取出上传文件的对象，在其它地方也可以使用
        this.fileList = [{ name: file.name, url: file.url }]; // 重新手动赋值filstList， 免得自定义上传成功了, 而fileList并没有动态改变， 这样每次都是上传一个对象
        //   this.form.cover = file.url;
      },
      // 确定班级
      dialogFormVisi() {
        this.dialogFormVisible = false;
        let str ='';
        this.checkedCities.map(item=>{
          str += `${item.id},`
        })
        if(str.length > 0){
          str = str.substr(0,str.length-1)
        }
        this.from.classGrable = str;
      },
      //移除之前
      beforeRemove(file, fileList) {
        let a = true;
        if (file && file.status==="success") {
          a = this.$confirm(`确定移除 ${ file.name }？`,{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type:"warning"
          }).then(()=>{
            this.fileList = [];
            this.$message({
              type:'success',
              message:'删除成功'
            })
          });
        }
        return a;
          // this.$confirm(`确定移除 ${ file.name }？`,{
          //   confirmButtonText: '确定',
          //   cancelButtonText: '取消',
          //   type: 'warning'
          // }).then(()=>{
          //   this.fileList = []
          //   this.$message({
          //     type: 'success',
          //     message: '删除成功!'
          //   });
          // });
      },
      cascaderHandleTwoChange(value){
        this.$httpteacher.axiosGetBy(this.$api.systemTaskDetail,{id:value},(res)=>{
          if(res.code === 200){
            this.from.title = res.info.task_title;
            if(res.info.task_class_id){
              this.from.taskclass = res.info.task_class_id;
            }
            this.editorText.setWEHtml(res.info.task_content);
            this.from.cover = res.info.cover
            this.anewUploadImg = res.info.task_cover;
          }
        })
      },
      // 次级选择器
      cascaderHandleChange(value){
        this.cascaderValueTwo = '';
        // return
        let id = value[value.length-1];
        let param = {
          class_id:id,
          type:0
        }
        this.$httpteacher.axiosGetBy(this.$api.systemTaskList,param,(res)=>{
          if(res.code == 200) {
            this.systemTaskListTwo = res.list;
          }
        },(err)=>{
          console.log(err);
          this.$message('暂无任务')
        })
      },
      //取消上传
      closeUpload(){
        this.$router.go(0)
      },
      deleCheckedCities(index){
        this.checkedCities.splice(index,1);
        let str ='';
        this.checkedCities.map(item=>{
          str += `${item.id},`
        })
        if(str.length > 0){
          str = str.substr(0,str.length-1)
        }
        this.from.classGrable = str;
      },
      dialogFormVisibleFalse(){
        this.checkedCities = [];
        this.dialogFormVisible = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  ::v-deep.el-upload-list__item{
    width: 200px!important;
    .el-upload-list__item-name{
      .el-icon-document{
        display: none;
      }
    }
  }
  .taskcenter_content{
    display: flex;
    flex-direction: column;
  }
  .task_content{
    .release_task{
      ::v-deep .upload-covercss{
        width: 378px;
      }
    }
  }
  .activeClass {
    background: #4d5aff;
    color: #ffffff;
  }
  .imggeshi {
    position: absolute;
    left: -70px;
    top: 24px;
    color: #999999;
    font-size: 12px;
  }
  .isbtn {
    margin-left: 47px;
    margin-top: 39px;
  }

  .task_header {
    display: flex;
    text-align: center;
    /*width: 100%;*/
  }
  .task_header p {
    color: #858ea6;
    font-size: 14px;
    width: 162px;
    height: 53px;
    line-height: 53px;
    border-radius: 6px;
    cursor: pointer;
  }
  .task_header .active {
    color: #0824ce;
    font-size: 16px;
    width: 162px;
    height: 53px;
    line-height: 53px;
    background: #ffffff;
    border-radius: 6px;
  }
  .task_header p:nth-child(1) {
    /*margin-left: 25px;*/
  }
  .task_content{
    position: relative;
  }
  .release_task {
    background: #ffffff;
    width: 100%;
    margin-top: -18px;
    overflow: hidden;
  }
  .task_list {
    background: #ffffff;
    width: 100%;
    margin-top: -18px;
    overflow: hidden;
  }

  .tasjlist_header {
    // width: 1600px;
    overflow: hidden;
    font-size: 14px;
    color: #333333;
  }
  .tasjlist_header p {
    margin-left: 33px;
  }
  .tasjlist_header div {
    text-align: center;
  }
  .tasjlist_header div:nth-child(1) {
    display: flex;
  }
  .tasjlist_header div:nth-child(1) span {
    margin-top: 8px;
    line-height: 32px;
    display: inline-block;
    height: 32px;
    padding: 0 20px;
  }

  .tasjlist_header div:nth-child(2) {
    display: flex;
  }
  .tasjlist_header div:nth-child(2) span {
    margin-top: 8px;
    line-height: 32px;
    display: inline-block;
    height: 32px;
    padding: 0 20px;
    margin-bottom: 20px;
  }
  el-form {
    margin-top: 30px;
  }

  .addimg {
    display: block;
    width: 22px;
    height: 22px;
    background: #1d33e3;
    color: #ffffff;
    border-radius: 50%;
    position: absolute;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    left: 33%;
    top: -60%;
  }
  #article_content{
    z-index: 1;
   width: 50%;
 }
  .studentNameTitle{
    line-height: 1;
    margin-top: 4px;
    font-size: 12px;
  }
  .studentNameContent{
    font-size: 12px;
    color: #999999;
    line-height: 1;
    margin: 0;
      ::v-deep img{
        display: none!important;
      }
  }
  .selectClassList{
    display: flex;
    align-items: center;
    span{
      margin-right: 20px;
      position: relative;
      display: inline-block;
      i{
        cursor: pointer;
        font-weight: bold;
        font-style: normal;
        position: absolute;
        font-size: 12px;
        right: -10px;
        top: -5px;
      }
    }
  }
</style>
